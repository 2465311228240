/** @jsxImportSource @emotion/react */
import React, {useMemo} from 'react'
import moment from 'moment'
import _ from 'lodash'
import {round} from 'utils'
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {formatTimestamp, TooltipHeader, TooltipList, TooltipListItem} from './Tooltip'
import Legend from './Legend'
import {useSeries} from 'hooks'

const SERIES = {
  // region NRS series
  'NI_NRS_FIR': {
    label: 'North Island NRS FIR',
    color: '#e3c902',
    tooltip: 'North Island NRS price, Fast Instantaneous Reserve (6s)'
  },
  'NI_NRS_SIR': {
    label: 'North Island NRS SIR',
    color: '#3cb44b',
    tooltip: 'North Island NRS price, Sustained Instantaneous Reserve (60s)'
  },
  'SI_NRS_FIR': {
    label: 'South Island NRS FIR',
    color: '#f032e6',
    tooltip: 'South Island NRS price, Fast Instantaneous Reserve (6s)'
  },
  'SI_NRS_SIR': {
    label: 'South Island NRS SIR',
    color: '#4363d8',
    tooltip: 'South Island NRS price, Sustained Instantaneous Reserve (60s)'
  },
  // endregion

  // region PRS series
  'NI_PRS_FIR': {
    label: 'North Island PRS FIR',
    color: '#ffd8b1',
    tooltip: 'North Island PRS price, Fast Instantaneous Reserve (6s)'
  },
  'NI_PRS_SIR': {
    label: 'North Island PRS SIR',
    color: '#e6194B',
    tooltip: 'North Island PRS price, Sustained Instantaneous Reserve (60s)'
  },
  'SI_PRS_FIR': {
    label: 'South Island PRS FIR',
    color: '#bfef45',
    tooltip: 'South Island PRS price, Fast Instantaneous Reserve (6s)'
  },
  'SI_PRS_SIR': {
    label: 'South Island PRS SIR',
    color: '#f58231',
    tooltip: 'South Island PRS price, Sustained Instantaneous Reserve (60s)'
  },
  // endregion

  // region WDS series
  'NI_WDS_FIR': {
    label: 'North Island WDS FIR',
    color: '#aaffc3',
    tooltip: 'North Island WDS price, Fast Instantaneous Reserve (6s)'
  },
  'NI_WDS_SIR': {
    label: 'North Island WDS SIR',
    color: '#469990',
    tooltip: 'North Island WDS price, Sustained Instantaneous Reserve (60s)'
  },
  'SI_WDS_FIR': {
    label: 'South Island WDS FIR',
    color: '#42d4f4',
    tooltip: 'South Island WDS price, Fast Instantaneous Reserve (6s)'
  },
  'SI_WDS_SIR': {
    label: 'South Island WDS SIR',
    color: '#911eb4',
    tooltip: 'South Island WDS price, Sustained Instantaneous Reserve (60s)'
  },
  // endregion

  // region Final series
  "NI_Final_FIR": {
    label: 'North Island Final FIR',
    color: '#d2c372',
    tooltip: 'North Island Final price, Fast Instantaneous Reserve (6s)'
  },
  "NI_Final_SIR": {
    label: 'North Island Final SIR',
    color: '#51b7d3',
    tooltip: 'North Island Final price, Sustained Instantaneous Reserve (60s)'
  },
  "SI_Final_FIR": {
    label: 'South Island Final FIR',
    color: '#63d9b1',
    tooltip: 'South Island Final price, Fast Instantaneous Reserve (6s)'
  },
  "SI_Final_SIR": {
    label: 'South Island Final SIR',
    color: '#c3329e',
    tooltip: 'South Island Final price, Sustained Instantaneous Reserve (60s)'
  },
  // endregion

  // region Interim series
  "NI_Interim_FIR": {
    label: 'North Island Interim FIR',
    color: '#e6597f',
    tooltip: 'North Island Interim price, Fast Instantaneous Reserve (6s)'
  },
  "NI_Interim_SIR": {
    label: 'North Island Interim SIR',
    color: '#9fca17',
    tooltip: 'North Island Interim price, Sustained Instantaneous Reserve (60s)'
  },
  "SI_Interim_FIR": {
    label: 'South Island Interim FIR',
    color: '#268de0',
    tooltip: 'South Island Interim price, Fast Instantaneous Reserve (6s)'
  },
  "SI_Interim_SIR": {
    label: 'South Island Interim SIR',
    color: '#9b4c30',
    tooltip: 'South Island Interim price, Sustained Instantaneous Reserve (60s)'
  }
  // endregion
}

const SERIES_OPTIONS = {
  xTicks: {every: 'hour', add: 6}
}

const CustomTooltip = ({active, series, payload}) => {
  if (active && payload?.length && series?.length) {
    const {timestamp, tradingPeriod, ...data} = payload[0].payload ?? {};
    return (
      <TooltipList>
        <TooltipHeader label={formatTimestamp(timestamp, {tradingPeriod})}/>
        {series.map((g, idx) => {
          const {label, color, strokeDasharray} = SERIES[g]
          return (
            <TooltipListItem
              key={idx}
              label={label}
              color={color}
              strokeDasharray={strokeDasharray}
              value={`$${round(data[g])}`}
            />
          )
        })
        }
      </TooltipList>
    )
  }
}

const ReservePrices = (
  {
    data,
    series = null,
    zoomCallback,
    currentInterval = null,
    tooltips = [],
    width = '100%',
    height = '100%'
  }
) => {
  // reformat the API data to something we can chart
  const dataByTimestamp = _.groupBy(data?.items || [], 'timestamp')
  const selectedSeries = series ? _.pick(SERIES, series) : SERIES

  const reservesData = useMemo(() => Object.keys(dataByTimestamp).map(
    timestamp => ({
      timestamp: moment(timestamp).valueOf(),
      ...(dataByTimestamp[timestamp].reduce((acc, curr) => ({
        ...acc,
        tradingPeriod: curr.tradingPeriod,
        [`${curr.region}_${curr.runType}_FIR`]: curr.firPrice,
        [`${curr.region}_${curr.runType}_SIR`]: curr.sirPrice
      }), {}))
    })
  ), [JSON.stringify(data)])

  const [graph, actions] = useSeries(selectedSeries, reservesData, {...SERIES_OPTIONS, zoomCallback})
  let [yMin, yMax] = graph.yDomain
  const [xMin, xMax] = [graph.data[0]?.timestamp || 0, _.last(graph.data)?.timestamp || 0]
  const currentIntervalX = currentInterval && currentInterval.valueOf()

  return (
    <>
      <ResponsiveContainer width={width} height={height}>
        <LineChart
          data={graph.data}
          {...actions.chartProps}
        >
          <XAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            dataKey="timestamp"
            scale="time"
            type="number"
            domain={graph.xDomain}
            ticks={graph.xTicks}
            tickFormatter={tick => moment(tick).format('HH:mm')}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            type="number"
            axisLine={false}
            tickLine={false}
            scale={graph.yScale}
            domain={[yMin, yMax]}
          />

          <defs>
            <linearGradient id="currentIntervalGradient" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#B1E9E6" stopOpacity={0}/>
              <stop offset="100%" stopColor="#B1E9E6" stopOpacity={0.4}/>
            </linearGradient>
          </defs>

          {currentIntervalX && (currentIntervalX > xMin) && (currentIntervalX < xMax) &&
            <>
              <ReferenceArea x2={currentIntervalX} fill="url(#currentIntervalGradient)"/>
              <ReferenceLine
                x={currentIntervalX}
                stroke="white"
              />
            </>
          }

          <Tooltip
            content={args => CustomTooltip({...args, series: graph.activeSeries})}
          />

          {graph.visibleSeries.map(g =>
            <Line
              key={g}
              type="linear"
              dataKey={g}
              stroke={SERIES[g].color}
              strokeDasharray={SERIES[g].strokeDasharray}
              animationDuration={300}
              dot={false}
              strokeWidth={2}
              activeDot={actions.isSeriesActive(g)}
            />
          )}

          {actions.renderZoomOverlay()}

          <CartesianGrid stroke="#fff" opacity={0.25} strokeDasharray="3 3"/>
        </LineChart>
      </ResponsiveContainer>
      <Legend
        series={selectedSeries}
        visible={graph.visibleSeries}
        onSeriesToggle={actions.setVisibleSeries}
        tooltips={tooltips}
      />
    </>
  )
}

export default ReservePrices
