import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CenteredCard, ControlledForm, ErrorBanner, PromiseButton } from 'components';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useSnackbar } from "contexts/SnackbarContext";
import { LogInCredentials, useTokens } from "contexts/TokensContext";

const SignIn = () => {
  const [state, actions] = useTokens()
  const snackbar = useSnackbar()

  const history = useHistory()

  const [formData, setFormData] = useState<Partial<LogInCredentials>>({})

  const signIn = async () => {
    try{
      await actions.create(formData as LogInCredentials)
      const location = (state.savedLocation && !state.savedLocation.match(/^\/?tokens\//)) ?
        state.savedLocation :
        '/'
      history.push(location)
      snackbar.show('Signed in successfully')
    }catch(err){
      console.log('err ---', err)
      snackbar.show('Sign in failed')
    }
  }

  const errors = state.errors.create?.meta

  return (
    <CenteredCard centerVertically={false}>
      <ControlledForm data={formData} errors={errors} onChange={setFormData} onSubmit={signIn}>
        <CardContent>
          <Typography variant='h6'>Sign in</Typography>
          <p>To open an account, contact us at <strong><a href="mailto:call@ems.co.nz">call@ems.co.nz</a></strong>.</p>
          {state.errors.create && <ErrorBanner>{state.errors.create.title}</ErrorBanner>}
          <FormGroup>
            <TextField name='email' type='text'/>
            <TextField name='password' type='password'/>
          </FormGroup>
        </CardContent>
        <CardActions>
          <PromiseButton fullWidth variant='contained' color='primary' type='submit' disabled={!formData.email || !formData.password}>
            Submit
          </PromiseButton>
        </CardActions>
        <Link className='link-small' to='/tokens/forgot'>Forgot Password</Link>
      </ControlledForm>
    </CenteredCard>
  )
}

export default SignIn