import React from 'react'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import KeyboardDoubleArrowUpRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowUpRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardDoubleArrowDownRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowDownRounded'

// To calculate if labels on a NZ map should be positioned left or right of the map
// latitude = x, longitude = y
export function positionNzMiddle(x, y){
  // we divide NZ in two halves by imagining a straight line defined by two points
  // from top/right (around Whakatane) to bottom/left (around Invercargill)
  const top    = {x: -34.85571, y: 179.34077}
  const bottom = {x: -47.87691, y: 165.60786}

  // y = mx + c
  const m = (bottom.y - top.y) / (bottom.x - top.x)
  const c = top.y - m * top.x

  // find x on our imaginary line for the given y
  const xIntersect = (y - c) / m

  // left or right of the line
  return {left: x > xIntersect, distance: Math.abs(x - xIntersect)}
}

export function iconBasedOnChange(base, change, textColor = '#000') {
  const iconProps = {
    inheritViewBox: true,
    color: '#fff',
    style: {
      color: textColor
    }
  }
  const defaultIcon = <RemoveRoundedIcon {...iconProps}/>

  if (base === undefined || change === undefined) return defaultIcon

  const pctChange = base ? (change / base) * 100 : change
  switch (true) {
    case pctChange > 5:
      return <KeyboardDoubleArrowUpRoundedIcon {...iconProps}/>
    case pctChange > 0:
      return <KeyboardArrowUpRoundedIcon {...iconProps}/>
    case pctChange < -5:
      return <KeyboardDoubleArrowDownRoundedIcon {...iconProps}/>
    case pctChange < 0:
      return <KeyboardArrowDownRoundedIcon {...iconProps}/>
    default:
      return defaultIcon
  }
}