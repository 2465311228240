/** @jsxImportSource @emotion/react */
import React from 'react'
import Marker, {ANNOTATION_PADDING, HEADER_LINE_HEIGHT, VALUE_LINE_HEIGHT} from './Marker'

export const MULTILINE_MARKER_DEFAULT_WIDTH = 125
export const MULTILINE_VALUE_LINE_HEIGHT = VALUE_LINE_HEIGHT + 2 // to allow extra padding when background is used

const MultilineMarker = (
  {
    coordinates,
    dx = 0,
    dy = 0,
    titleLines,
    textLines,
    visible = true,
    labelStyle = {},
    markerStyle = {},
    connectorStyle = {},
    textStyle = {},
    icon = null,
    onMouseOver = null,
    height = null,
    width = null,
    children = null
  }) => {

  const markerWidth = width || MULTILINE_MARKER_DEFAULT_WIDTH
  const markerHeight = height ||
    (3 * ANNOTATION_PADDING + titleLines.length * HEADER_LINE_HEIGHT + textLines.length * MULTILINE_VALUE_LINE_HEIGHT)
  const textLeftMargin = 2 * ANNOTATION_PADDING

  const title = <>
    {titleLines.map((line, idx) =>
      <tspan key={idx} x={textLeftMargin} dy={idx === 0 ? 0 : HEADER_LINE_HEIGHT}>{line}</tspan>)}
  </>

  const text = <>
    {textLines.map(({text, color}, idx) =>
      <tspan key={idx}
             x={textLeftMargin}
             dy={idx === 0
               ? ANNOTATION_PADDING + ((titleLines.length - 1) * HEADER_LINE_HEIGHT + (MULTILINE_VALUE_LINE_HEIGHT - VALUE_LINE_HEIGHT) / 2)
               : MULTILINE_VALUE_LINE_HEIGHT}
             fill={color}
      >{text}</tspan>
    )}
  </>

  return <Marker
    coordinates={coordinates}
    dx={dx}
    dy={dy}
    height={markerHeight}
    width={markerWidth}
    title={title}
    text={text}
    visible={visible}
    labelStyle={labelStyle}
    markerStyle={markerStyle}
    textStyle={textStyle}
    connectorStyle={connectorStyle}
    icon={icon}
    onMouseOver={onMouseOver}
    children={children}
  />
}

export default MultilineMarker
