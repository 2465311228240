/** @jsxImportSource @emotion/react */
import React from 'react'
import MultiSelect from 'components/MultiSelect'
import Grid from '@mui/material/Grid'

export const RESERVE_PRICES_REGIONS = [
  {id: 'NI', label: 'North Island'},
  {id: 'SI', label: 'South Island'},
]

// Schedule type (PRS, NRS, WDS, RTD, Final, Interim)
export const RESERVE_PRICES_RUN_TYPES = [
  {id: 'PRS', label: 'PRS'},
  // These 2 are included in PRS
  // {id: 'A', label: 'PRSS'},
  // {id: 'G', label: 'PRSL'},
  {id: 'NRS', label: 'NRS'},
  // These 2 are included in NRS
  // {id: 'N', label: 'NRSS'},
  // {id: 'L', label: 'NRSL'},
  // No API support at the moment
  // {id: 'R', label: 'RTD'},
  {id: 'WDS', label: 'WDS'},
  {id: 'Final', label: 'Final'},
  {id: 'Interim', label: 'Interim'}
]

const ReservePricesFilter = ({filter, onChange}) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <MultiSelect
          value={filter.regions}
          label="Region"
          allSelectedLabel="All Regions"
          noneSelectedLabel="None"
          options={RESERVE_PRICES_REGIONS}
          onChange={regions => onChange({...filter, regions})}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MultiSelect
          value={filter.runTypes}
          label="Run Type"
          allSelectedLabel="All Run Types"
          noneSelectedLabel="None"
          options={RESERVE_PRICES_RUN_TYPES}
          onChange={runTypes => onChange({...filter, runTypes})}
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

export default ReservePricesFilter
