/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import ModelTrainingIcon from '@mui/icons-material/ModelTraining'
import {Graphs, WidgetContainer} from 'components'
import {useDependency, useFilter, useResource} from 'hooks'
import {useSavedFilters} from 'contexts/SavedFiltersContext'
import {Widgets} from './'
import {currentIntervalTime} from 'utils'
import {DEFAULT_WIDGET_REFRESH_MS} from 'constants'
import moment from "moment/moment"

const DEFAULT_FILTER = {
  regions: ['NI', 'SI'],
  runTypes: ['NRS', 'Interim'],
}

const CURRENT_INTERVAL_REFRESH = 1000 * 60 * 5

const ReservePricesGraph = (props) => {
  const widget = Widgets['reserve-prices-graph']
  const [filter, setFilter] = useFilter(null, 'reservePrices')
  const savedFilters = useSavedFilters()
  const [reserves, reservesActions] = useResource('reserves')
  const [reservesLoading, _result, reloadReserves] = useDependency(
    () => filter && Object.keys(filter).length && reservesActions.request('prices', filter),
    [filter]
  )
  const [currentInterval, setCurrentInterval] = useState(null)
  const tooltips = useState(false)

  const regions = filter?.regions || []
  const runTypes = filter?.runTypes || []
  const series = regions.map(
    region => runTypes.map(
      runType => ['FIR', 'SIR'].map(
        loadType => `${region}_${runType}_${loadType}`
      )
    )
  ).flat(2).sort()

  const [zoom, setZoom] = useState({})
  const zoomCallback = zoom => setZoom(zoom)
  const updateCurrentInterval = () => setCurrentInterval(currentIntervalTime())

  useEffect(() => {
    const initialFilter = savedFilters.load(widget) || DEFAULT_FILTER
    setFilter({...initialFilter, ...filter})
  }, [])

  // display current time
  useEffect(() => {
    updateCurrentInterval()
    const _timer = setInterval(updateCurrentInterval, CURRENT_INTERVAL_REFRESH)
    return () => _timer && clearInterval(_timer)
  }, [])

  return (
    <WidgetContainer
      widget={widget}
      title="Reserve Prices ($/MWh)"
      icon={<ModelTrainingIcon/>}
      lastUpdatedAt={moment().valueOf()}
      lastUpdatedAtAddMinutes={0}
      loading={reservesLoading}
      onWidgetRefresh={reloadReserves}
      widgetAutoRefreshMs={DEFAULT_WIDGET_REFRESH_MS}
      renderFilter={() => <Graphs.ReservePricesFilter filter={filter} onChange={setFilter}/>}
      onFilterSave={() => savedFilters.update(widget, filter)}
      tooltips={tooltips}
      zoom={zoom}
      {...props}
    >
      <Graphs.ReservePrices
        data={reserves.prices?.data}
        series={series}
        zoomCallback={zoomCallback}
        currentInterval={currentInterval}
        tooltips={tooltips}
      />
    </WidgetContainer>
  )
}

export default ReservePricesGraph
