/** @jsxImportSource @emotion/react */
import React from 'react'
import Typography from '@mui/material/Typography'
import EmailIcon from '@mui/icons-material/Email'
import FaceIcon from '@mui/icons-material/Face'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { PageContainer } from 'components'
import { useAuthorization } from 'hooks'
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import Link from '@mui/material/Link';
import _ from 'lodash'

const GROUP_MAPPINGS = {
  EM6_CARB: "Current and Historic Carbon Intensity",
  EM6_GWAP: "Generation Weighted Average Prices",
  EM6_NTWK: "Network Information",
  EM6_PRIC: "Market Prices and Trading Page",
  EM6_PUBL: "Premium Dashboard",
  EM6_RCPD: "Regional Peaks",
  EM6_RSRV: "Reserves Information",
  EM6_RT: "Real Time Load, Generation and HVDC (1min)",
  EM6_SCAD: "SCADA Load, Generation and HVDC",
}

const Profile = () => {
  const { user, groups } = useAuthorization()
  const subscribedGroups = _.intersection(Object.keys(GROUP_MAPPINGS), groups)
  const availableGroups = _.difference(Object.keys(GROUP_MAPPINGS), groups)
  const contactEmail = process.env.REACT_APP_CONTACT_EMAIL

  const itemStyleProps = {
    primaryTypographyProps: {color: 'text.secondary'},
    secondaryTypographyProps: {color: 'text.primary'},
  }

  return (
    <PageContainer>
      <Card css={styles.container}>
        <CardContent css={styles.content}>
          <CardHeader title="Profile"/>
          <List>
            <ListItem>
              <ListItemIcon><EmailIcon /></ListItemIcon>
              <ListItemText
                {...itemStyleProps}
                primary='Email'
                secondary={user.email}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon><NotificationsIcon /></ListItemIcon>
              <ListItemText
                {...itemStyleProps}
                primary="Active Subscriptions"
                secondary={
                  subscribedGroups?.length > 0 ?
                    subscribedGroups.map((group: string) => <Typography variant="body2">{GROUP_MAPPINGS[group]}</Typography>) :
                    <Typography variant="body2">None</Typography>
                }
              >
              </ListItemText>
            </ListItem>
            <ListItem >
              <ListItemIcon><NotificationAddIcon /></ListItemIcon>
              <ListItemText
                {...itemStyleProps}
                primary='Available Subscriptions'
                secondary={
                  availableGroups?.length > 0 ?
                    availableGroups.map((group: string) => <Typography variant="body2">{GROUP_MAPPINGS[group]}</Typography>) :
                    <Typography variant="body2">None</Typography>
                }
              >
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText disableTypography>
                <Typography variant="body2">
                  For changes to your subscription, email us at <Link href={`mailto:${contactEmail}`}>{contactEmail}</Link>.
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </PageContainer>
  );
}

const styles = {
  container: {
    backgroundColor: '#003b59',
    height: '100%',
    width: '100%',
    '& .MuiCardHeader-title': {
      fontSize: '1.1rem',
      fontWeight: 800,
    }
  },
  content: {
    maxWidth: 'none',
    '& .MuiListItemText-primary': {
      fontWeight: 600,
    }
  }
}

export default Profile