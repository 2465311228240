/** @jsxImportSource @emotion/react */
import React from 'react'
import Grid from '@mui/material/Grid'
import { PageContainer } from 'components'
import { StylesObject } from "types"
import { Widget } from '../../widgets'

const Home = () =>
  <PageContainer>
    <Grid container spacing={2}>

      <Widget id="regional-price-map"/>

      <Widget id="carbon-dial"/>

      <Widget id="previous-day-prices-graph"/>

      <Widget id="node-price-map"/>

      <Widget id="node-load-map"/>

      <Widget id="island-load-graph"/>

      <Widget id="hvdc-graph"/>

      <Widget id="reserves-graph"/>

      <Widget id="reserve-prices-graph"/>

    </Grid>
  </PageContainer>

const styles = {

} as StylesObject

export default Home