/** @jsxImportSource @emotion/react */
import React from 'react'
import Chip from '@mui/material/Chip'
import {useVersion} from '../hooks'

const COLORS = {
  staging: '#1BCD7F',
  development: '#D7B22C',
}

export const EnvironmentLabel = () => {

  const label = process.env.REACT_APP_ENVIRONMENT_LABEL
  const color = COLORS[(label || '').toLowerCase()]
  const [version] = useVersion()

  return (
    label
      ? <Chip css={styles.chip} style={{backgroundColor: color}} label={`${label} v${version}`}/>
      : <span style={{display: 'none', fontSize: 'smaller'}}>{`Version: ${version}`}</span>
  )
}

const styles = {
  chip: {
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 15,
  },
}

export default EnvironmentLabel