/** @jsxImportSource @emotion/react */
import React, {useState} from 'react'
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps"
import Marker, {ANNOTATION_PADDING, CORNERS_RADIUS, DropShadow, HEADER_LINE_HEIGHT} from './Marker'
import MultilineMarker, { MULTILINE_MARKER_DEFAULT_WIDTH, MULTILINE_VALUE_LINE_HEIGHT } from './MultilineMarker'
import NZGrid from './data/NZGrid.json'
import PriceLocations from './data/PriceLocations.json'
import IslandLocations from './data/IslandLocations.json'
import PriceRanges from './data/PriceRanges.json'
import useZoom from './useZoom'
import { trailingZero } from 'utils'

const SERIES = PriceRanges.reduce((acc, curr) => ({...acc, [curr.id]: curr}), {})

const RegionalPrice = ({data, zoomCallback}) => {
  const visibleSeries= Object.keys(SERIES)
  const [orderedNodes, setOrderedNodes] = useState(PriceLocations)

  const priceRange = price => price ? PriceRanges.find(range => range.max > price || !range.max) : null

  const itemsByGridZoneId = data?.region?.items?.reduce((acc, curr) => ({
    ...acc,
    [curr.gridZoneId]: {...curr, priceRange: priceRange(curr.price)}
  }), {}) || {}

  const reservesByIslandId = data?.reserve?.items.reduce((acc, curr) => ({
    ...acc,
    [curr.region]: {...curr, sirPriceRange: priceRange(curr.sirPrice), firPriceRange: priceRange(curr.firPrice)}
  }), {}) || {}

  const {position, zoomableGroupProps} = useZoom({coordinates: [173, -41.1], zoomCallback})

  const handleMarkerMouseOver = node => {
    // move node to the back so it gets drawn last on top of all the others
    const updated = [...orderedNodes.filter(({gridZoneId}) => gridZoneId !== node.gridZoneId), node]
    setOrderedNodes(updated)
  }

  return (
    <div className='map' css={styles.container}>
      <ComposableMap
        projection="geoMercator"
        projectionConfig={{
          center: [0,0],
          scale: 2070 * position.zoom
        }}
        fill='white'
      >
        <defs>
          <DropShadow/>
        </defs>

        <ZoomableGroup {...zoomableGroupProps}>
          <Geographies geography={NZGrid}>
            {({ geographies }) =>
              geographies.map((geo) => {

                const item = itemsByGridZoneId[geo.properties.GZ]
                const color = item?.priceRange?.color

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={color}
                  />)
              })
            }
          </Geographies>

          {orderedNodes.map(node => {
            const {gridZoneId, gridZoneName, longitude, latitude, xOffset} = node
            const item = itemsByGridZoneId[gridZoneId]
            const visible = !item || visibleSeries.includes(String(item?.priceRange?.id))
            const text = item?.price ? `$${trailingZero(item?.price)}` : ' - '
            const color = item?.priceRange?.color
            const textColor = item?.priceRange?.textcolor || '#fff'

            return (
              item && <Marker
                        key={gridZoneId}
                        coordinates={[longitude, latitude]}
                        dx={xOffset}
                        title={gridZoneName}
                        text={text}
                        visible={visible}
                        labelStyle={{fill: color}}
                        markerStyle={{fill: color, stroke: '#fff'}}
                        textStyle={{fill: textColor}}
                        onMouseOver={() => handleMarkerMouseOver(node)}
                      />
            )
          })}

          {IslandLocations.map(island => {
            const {islandId, islandName, longitude, latitude, xOffset, yOffset} = island
            const item = reservesByIslandId[islandId]
            const visible = !!item
            const sirColor = item?.sirPriceRange?.color || '#222'
            const sirColorText = item?.sirPriceRange?.textcolor || '#fff'
            const firColor = item?.firPriceRange?.color || '#222'
            const firColorText = item?.firPriceRange?.textcolor || '#fff'

            const sirBoxY = 2 * ANNOTATION_PADDING + 2 * HEADER_LINE_HEIGHT
            const firBoxY = sirBoxY + MULTILINE_VALUE_LINE_HEIGHT
            const boxWidth = MULTILINE_MARKER_DEFAULT_WIDTH

            const titleLines = [islandName, 'Reserves']
            const textLines = [
              {
                text: `SIR $${trailingZero(item?.sirPrice)}`,
                color: sirColorText,
              },
              {
                text: `FIR $${trailingZero(item?.firPrice)}`,
                color: firColorText
              }
            ]

            return (
              item && <MultilineMarker
                key={islandId}
                coordinates={[longitude, latitude]}
                height={(2 * ANNOTATION_PADDING + 2 * HEADER_LINE_HEIGHT + 2 * MULTILINE_VALUE_LINE_HEIGHT)}
                dx={xOffset}
                dy={yOffset}
                titleLines={titleLines}
                textLines={textLines}
                visible={visible}
                labelStyle={{fill: sirColor}}
                markerStyle={{display: 'none'}}
                textStyle={{fill: sirColorText}}
                connectorStyle={{display: 'none'}}
                children={<>
                  <rect x={0} rx={CORNERS_RADIUS} y={sirBoxY} width={boxWidth} height={MULTILINE_VALUE_LINE_HEIGHT}
                        fill={sirColor}/>
                  <rect x={0} rx={CORNERS_RADIUS} y={firBoxY} width={boxWidth} height={MULTILINE_VALUE_LINE_HEIGHT}
                        fill={firColor}/>
                </>}
              />
            )
          })}

        </ZoomableGroup>
      </ComposableMap>
      {/* disable map legend for now while we're getting UI feedback
      <Legend
        series={SERIES}
        visible={visibleSeries}
        onSeriesToggle={setVisibleSeries}
      />
      */}
    </div>
  )
}

const styles = {
  container: {
  },
}

export default RegionalPrice